
import { defineComponent, watch } from 'vue'

import { usePersonalForm } from '@/use/personal-form'

import { useIdentityGuard } from '@/use/login'
import { Config } from '@/lib/di/config'
import MultipleDiagnoseInput from '@/components/personalquestions/MultipleDiagnoseInput.vue'
import CenteredLoading from '@/components/common/CenteredLoading.vue'
import { computedParam } from '@/use/route'
import { useRouter } from 'vue-router/composables'

export default defineComponent({
  name: 'PersonalQuestions',
  components: { MultipleDiagnoseInput, CenteredLoading },
  setup () {
    const id = computedParam('id')
    const { debug } = Config.get()

    const personalForm = usePersonalForm(id)

    const router = useRouter()

    watch(personalForm.error, (err) => {
      if (err?.status === 401) {
        router.push({ name: 'Welcome', params: { id: id.value } })
          .catch(err => console.error('Failed to navigate', err))
      }
    })

    useIdentityGuard({ name: 'Welcome', params: { id: id.value } })

    async function next () {
      await personalForm.saveEager()
      await router.push({ name: 'Form', params: { id: id.value } })
    }

    return { id, debug, next, ...personalForm }
  },
  metaInfo: {
    title: 'Bakgrund'
  }
})
